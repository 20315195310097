import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/home.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../components/HomeLayout";
import { TiFlashOutline } from "react-icons/ti";
import { FiDownloadCloud } from "react-icons/fi";
import { SiSlideshare } from "react-icons/si";
import { PiPlugsConnected } from "react-icons/pi";
import { TbMessages } from "react-icons/tb";
import { BiVideoRecording } from "react-icons/bi";
import { motion } from "framer-motion";
import { FaStar } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { FiZap } from "react-icons/fi";
import { FiGlobe } from "react-icons/fi";
import { FiVoicemail } from "react-icons/fi";
import { LuScreenShare } from "react-icons/lu";
import { BsWindows, BsApple } from "react-icons/bs";
import { TbDeviceDesktop } from "react-icons/tb";
import { TbDeviceMobile } from "react-icons/tb";
import { LuCalendarClock } from "react-icons/lu";
import { LuUsers, LuCheck } from "react-icons/lu";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import VideoModal from '../components/VideoModal';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AiTwotoneSecurityScan } from "react-icons/ai";
import { TbReport } from "react-icons/tb";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { TbLayoutDashboard } from "react-icons/tb";
import { TbUsersPlus } from "react-icons/tb";
import { MdOutlineSecurity } from "react-icons/md";
import { LuMousePointerClick } from "react-icons/lu";
import { GrSecure } from "react-icons/gr";
import { FaShieldAlt } from "react-icons/fa";
import { TbShieldCheckFilled } from "react-icons/tb";
import { TbShieldLock } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";

export default function Home() {
  const isMobileView = useMediaQuery({ query: "(max-width: 1025px)" });
  const [planDuration, setPlanDuration] = useState("Monthly");
  const [plansPricing, setPlansPricing] = useState([4, 10, 25, 'Call for Consultation: 1866-307-8708']);
  const plansFeatures = [
    [
      "Secure Customizable Analytic Dashboards with - AI",
      "Advanced Predictive Analytics",
      "Engaging Interactive Visualizations",
      "Seamless Data Comparison and Validation with - AI",
      "End-to-End Encryption",
      "Interoperability and Secure Cloud Hosting",
    ],
    [
      "AI-Powered Secure Customizable Analytic Dashboards",
      "AI-Powered Advanced Predictive Analytics",
      "Engaging Interactive Visualizations",
      "AI-Powered Seamless Data Comparison and Validation",
      "End-to-End Encryption",
      "Interoperability and Secure Cloud Hosting",
    ],
    [
      "Secure Customizable Analytic Dashboards with - AI",
      "Advanced Predictive Analytics",
      "Engaging Interactive Visualizations",
      "Seamless Data Comparison and Validation with - AI",
      "End-to-End Encryption",
      "Interoperability and Secure Cloud Hosting",
    ],
    [
      "Secure Customizable Analytic Dashboards with - AI",
      "Advanced Predictive Analytics",
      "Engaging Interactive Visualizations",
      "Seamless Data Comparison and Validation with - AI",
      "End-to-End Encryption",
      "Interoperability and Secure Cloud Hosting",
      "Emergency Management",
      "Secure Crisis management Tools",
    ],
  ];
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (planDuration == "Monthly") {
      setPlansPricing([4, 10, 25, 'Call for Consultation: 1866-307-8708']);
    } else {
      setPlansPricing([48, 120, 300, 'Call for Consultation: 1866-307-8708']);
    }
  }, [planDuration]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };  

  const owlRef = useRef(null);

  // Function to go to the next slide
  const goToNextSlide = () => {
    owlRef.current.next();
  };

  // Function to go to the previous slide
  const goToPrevSlide = () => {
    owlRef.current.prev();
  };

  const location = useLocation();

  const scrollTo = (section) => {
    const Section = document.getElementById(section);
    if (Section) {
      Section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const pagePath = window.location.pathname;
    const hashFragment = window.location.hash;
    const fullURL = pagePath + hashFragment;
    if (fullURL !== "/" && fullURL !== "/terms" && fullURL !== "/privacy") {
      console.log(fullURL.replace("/#", ""));
      setTimeout(scrollTo(fullURL.replace("/#", "")), 100);
    }
  }, [window.location.pathname, window.location.hash]);

  const downloads = [
    {
      name: "Windows",
      link: ""
    },
    {
      name: "Mac",
      link: ""
    },
    {
      name: "Android",
      link: ""
    },
    {
      name: "IOS",
      link: ""
    },
  ];

  const features = [
    {
      name: "Advanced real-time video technology for ultra-low latency and crystal clear video and audio.",
      icon: TiFlashOutline,
    },
    {
      name: "Download desktop and for Windows, Mac, Android and iOS.",
      icon: FiDownloadCloud,
    },
    {
      name: "Screen sharing, real time interactions and document sharing to get work done effectively.",
      icon: SiSlideshare,
    },
    {
      name: "Global coverage, connect with anyone, from anywhere at anytime.",
      icon: PiPlugsConnected,
    },
    {
      name: "One on One and group messaging from anywhere at anytime.",
      icon: TbMessages,
    },
    {
      name: "Easy to use meeting recordings.",
      icon: BiVideoRecording,
    },
  ];

  const whyus = [
    {
      name: "User-Centric Design",
      desc: "Our platform focuses on delivering an intuitive user experience, enabling users to prioritize insights over software navigation for engaging data exploration.",
      icon: LuMousePointerClick,
    },
    {
      name: "Inclusive Analytics for All",
      desc: "Designed for every skill level, Reports Detective offers role-based access that equips all users, from beginners to experts, with tools to quickly uncover insights.",
      icon: TbBrandGoogleAnalytics,
    },
    {
      name: "Customizable Reporting Solutions",
      desc: "Easily create and distribute advanced reports that fit your needs, allowing you to present data in a format that resonates with your audience.",
      icon: TbReport,
    },
    {
      name: "Interactive Dashboards and Visualizations",
      desc: "Build dynamic dashboards that engage viewers, using AI-driven insights and predictive analytics to turn complex data into compelling stories.",
      icon: TbLayoutDashboard,
    },
    {
      name: "Seamless Collaboration Tools",
      desc: "Enhance teamwork with features that facilitate effortless sharing of findings, ensuring that everyone stays aligned and informed, whether in real-time or through scheduled reports.",
      icon: TbUsersPlus,
    },
    {
      name: "Robust Data Security",
      desc: "Your data's safety is our priority, with comprehensive access controls and security protocols that keep your insights confidential and secure.",
      icon: TbShieldLock,
    },
  ];

  const testimonials = [
    {
      review: "Data analysis has always felt overwhelming, but Reports Detective has changed that for me. Its intuitive dashboards and AI-driven insights make it easy to derive actionable information, helping my business thrive even from afar.",
      name: "Tye Evans",
      designation: "Entrepreneur & Owner, Token Investment Inc.",
    },
    {
      review: "Reports Detective has completely transformed how our team handles data. The platform simplifies report generation and analysis, allowing us to make informed decisions quickly. As a  certified women -owned business, I appreciate how Perplexity understands the unique challenges we face.",
      name: "Patricia Smith",
      designation: "",
    },
    {
      review: "Using Reports Detective has streamlined our decision-making process. The seamless data validation and beautiful visualizations ensure that we stay on track, and I can confidently recommend it to any organization looking to enhance their analytics capabilities.",
      name: "Lillian Harris",
      designation: "CEO, H & H Global Consulting",
    },
  ];
  
  const stats = [
    { name: 'Offices worldwide', value: '12' },
    { name: 'Full-time colleagues', value: '300+' },
    { name: 'Hours per week', value: '40' },
    { name: 'Paid time off', value: 'Unlimited' },
  ]

  return (
    <HomeLayout>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main id="home">
          <section className="main-home lg:min-h-screen flex flex-col h-auto main w-full mx-auto" style={{ backgroundImage: 'url("https://cdn.pixabay.com/photo/2024/03/06/00/30/ai-generated-8615473_1280.png")', transition: 0.25 }}>
            <div className="bg-overlay h-100 w-100 flex-1">
              <div className="container">
                <div className="w-full lg:w-9/12 mx-auto">
                  <div className="w-full pb-10 pt-6 lg:pr-4 text-center">
                    <div>
                      <p className="uppercase pl-1 text-white-50">A Product of Perplexity</p>
                      <h1 className="mb-6 text-white">
                      Empowering Insights with <span className="text-main-alt">Reports Detective</span>
                      </h1>
                      <p className="text-gray-300">Transforming Data into Actionable Intelligence for Diverse Industries</p>
                      <div className="flex flex-row justify-center space-x-3 mt-8">
                        <button className="btn-main" onClick={openModal}>
                          Watch the Video
                        </button>
                        <a href="/#plans" className="btn-main-alt">
                          Try for Free
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="whyus" className="section bg-main">
            <div class="container">
              <div className="text-center mb-9">
                {/* <h5 class="small-title">SUBSCRIBE</h5> */}
                <h4 class="mb-9 mx-auto !text-white">Why Choose Reports Detective?</h4>
                <div className="w-2/12 bg-white opacity-30 p-[0.5px] mx-auto"></div>
              </div>
              <div>
                <div className="text-center space-y-5 lg:space-y-0 lg:space-x-5 mx-auto w-full lg:w-10/12 text-neutral-100 font-light text-lg">
                  Reports Detective, empowers institutions, government and veteran-owned organizations to embrace a data-driven culture. Our platform simplifies report generation and analysis powered by AI, turning complex data into clear, actionable insights, enabling teams easily validate data and make informed decisions swiftly and effectively.
                </div>
              </div>
            </div>
          </section>
          <section id="why-us" className="overflow-hidden whyus bg-light section">
            <div className="container">
              <div className="text-center mb-4">
                <h5 class="small-title">Why Us</h5>
                <h4 class="mb-3">See what we have to offer</h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-16">
                {whyus.map((feature) => (
                  <div key={feature.name} className="">
                    <div className="">
                      <span>
                        <feature.icon size={24} className="" />
                      </span>
                      <h5>{feature.name}</h5>
                      <p>{feature.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section id="features" class="section">
            <div class="container">
              <div className="text-center mb-10">
                <h5 class="small-title">Features</h5>
                <h4 class="mb-3">Key features of the product</h4>
              </div>
              <div className="space-y-16 lg:space-y-32 features-list">
                <div class="flex flex-col lg:flex-row justify-between">
                  <div class="w-full lg:w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <LuUsers size={24} />
                        </span>
                      </div>
                      <h5>Customizable Analytic Dashboards</h5>
                      <p class="mb-4">Effortlessly customize and explore your data with intuitive dashboards. With the option to leverage embedded AI for assistance, you can overcome the challenge of fragmented data sources and derive actionable insights more efficiently.</p>
                      {/* <div class="grid grid-cols-2 gap-3">
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Donec quam felis</span>
                          </p>
                        </div>
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Ultricies nec</span>
                          </p>
                        </div>
                      </div> */}
                      <div class="mt-8">
                        <a href="/#plans" className="btn-main">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-5/12 mt-6 lg:mt-0">
                    <div class="ml-auto mt-3">
                      <img src="/images/customizable-dashboard.jpg" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col-reverse lg:flex-row justify-between">
                  <div class="w-full lg:w-5/12 mt-6 lg:mt-0">
                    <div class="mr-auto mt-3">
                      <img src="/images/predictive-analytics.jpg" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                  <div class="w-full lg:w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <LuCalendarClock size={24} />
                        </span>
                      </div>
                      <h5>Advanced Predictive Analytics</h5>
                      <p class="mb-4">Utilize powerful predictive models to forecast trends and identify risks. This feature addresses the lack of predictive analytics, enabling organizations to make informed decisions and enhance strategic planning.</p>
                      <div class="mt-8">
                        <a href="/#plans" className="btn-main">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col lg:flex-row justify-between">
                  <div class="w-full lg:w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <FiVoicemail size={24} />
                        </span>
                      </div>
                      <h5>Engaging Interactive Visualizations</h5>
                      <p class="mb-4">Create beautiful, interactive charts that simplify complex data. This helps alleviate inefficient data visualization practices, ensuring stakeholders can quickly grasp insights and make data-driven decisions.</p>
                      <div class="mt-8">
                        <a href="/#plans" className="btn-main">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-5/12 mt-6 lg:mt-0">
                    <div class="ml-auto mt-3">
                      <img src="/images/interactive-visualizations.jpg" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col-reverse lg:flex-row justify-between">
                  <div class="w-full lg:w-5/12 mt-6 lg:mt-0">
                    <div class="mr-auto mt-3">
                      <img src="/images/ai-dashboard.jpg" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                  <div class="w-full lg:w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <TbMessages size={24} />
                        </span>
                      </div>
                      <h5>Seamless Data Comparison and Validation</h5>
                      <p class="mb-4">Our AI-powered platform simplifies report generation and analysis, turning complex data into clear, actionable insights. No more sifting through endless spreadsheets or wondering if the numbers add up. With Reports Detective, your team can:</p>
                      <ul className="list-disc ml-4">
                        <li>Validate data instantly with the highest accuracy</li>
                        <li>Make informed decisions at lightning speed</li>
                        <li>Drive actionable insights with minimal effort</li>
                      </ul>
                      <div class="mt-8">
                        <a href="/#plans" className="btn-main">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*           
          <section id="features" class="section">
            <div class="container">
              <div className="text-center mb-10">
                <h5 class="small-title">Features</h5>
                <h4 class="mb-3">Key features of the product</h4>
              </div>
              <div className="space-y-32">
                <div class="flex flex-row justify-between">
                  <div class="w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <LuBarChart2 size={24} />
                        </span>
                      </div>
                      <h5>Improve your Marketing business</h5>
                      <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and regular.</p>
                      <div class="grid grid-cols-2 gap-3">
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Donec quam felis</span>
                          </p>
                        </div>
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Ultricies nec</span>
                          </p>
                        </div>
                      </div>
                      <div class="mt-8">
                        <Link to="/signup" className="btn-main">
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="w-5/12">
                    <div class="ml-auto mt-3">
                      <img src="http://invoza.angular.themesdesign.in/assets/images/features/img-1.png" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                </div>
                <div class="flex flex-row justify-between">
                  <div class="w-5/12">
                    <div class="mr-auto mt-3">
                      <img src="http://invoza.angular.themesdesign.in/assets/images/features/img-2.png" alt="" class="object-contain rounded-lg border border-neutral-100" />
                    </div>
                  </div>
                  <div class="w-5/12">
                    <div>
                      <div class="mb-8">
                        <span class="badge-rounded">
                          <LuPieChart size={24} />
                        </span>
                      </div>
                      <h5>Increase your Marketing Performance</h5>
                      <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and regular.</p>
                      <div class="grid grid-cols-2 gap-3">
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Donec quam felis</span>
                          </p>
                        </div>
                        <div>
                          <p className="flex space-x-3 items-center">
                            <LuCheck size={22} color="#34d1bf" />
                            <span>Ultricies nec</span>
                          </p>
                        </div>
                      </div>
                      <div class="mt-8">
                        <Link to="/signup" className="btn-main">
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section id="clients" class="section bg-light">
            <div class="container">
              <div className="text-center mb-10">
                <h5 class="small-title">TESTIMONIAL</h5>
                <h4 class="mb-3">What our Client Say</h4>
              </div>
              <div className="hidden lg:flex justify-end space-x-3 mb-3">
                <div class="owl-nav">
                  <div class="owl-prev" onClick={goToPrevSlide}>
                    <FaAngleLeft />
                  </div>
                  <div class="owl-next" onClick={goToNextSlide}>
                    <FaAngleRight />
                  </div>
                </div>
              </div>
              <div>
                <OwlCarousel ref={owlRef} items={isMobileView ? 1 : 2} className="owl-theme" loop margin={25} autoplay={false} autoplayTimeout={3000}>
                  {testimonials.map((testimonial) => (
                    <div key={testimonial.name} class="item">
                      <div>
                        <p>" {testimonial.review} "</p>
                        <div className="user-details mt-10">
                          <span class="badge-rounded text-main text-lg">{testimonial.name.charAt(0)}</span>
                          <h6 className="text-lg mt-1 mb-0">{testimonial.name}</h6>
                          <div className="mb-2 font-medium">{testimonial.designation}</div>
                          <div className="text-neutral-700">- Reports Detective User</div>
                          <div className="flex mt-1 ml-[0.6rem] space-x-0.5">
                            {Array.from({ length: 5 }, (_, index) => (
                              <div>
                                <FaStar size={13} className="text-[#fbb13c]" />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              
              {/* <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img
                  alt="Transistor"
                  src="https://tailwindui.com/plus/img/logos/158x48/transistor-logo-gray-900.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-30"
                />
                <img
                  alt="Reform"
                  src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-gray-900.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-30"
                />
                <img
                  alt="Tuple"
                  src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-gray-900.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-30"
                />
                <img
                  alt="SavvyCal"
                  src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-gray-900.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 opacity-30"
                />
                <img
                  alt="Statamic"
                  src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-gray-900.svg"
                  width={158}
                  height={48}
                  className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1 opacity-30"
                />
              </div> */}

            </div>
          </section>

          {/* <section id="stats" className="section bg-main">
            <div class="container">
              <div className="text-center mb-10">
                <h4 class="mb-3 !text-white">Best Solutions for your Business</h4>
                <p className="text-white-50 w-7/12 mx-auto">To achieve this, it would be necessary to have uniform grammar, pronunciation and more common that of the individual languages.</p>
              </div>
              
              <div className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4 text-center">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col-reverse gap-1">
                    <dt className="text-base/7 text-white-50">{stat.name}</dt>
                    <dd className="text-4xl font-semibold tracking-tight text-white">{stat.value}</dd>
                  </div>
                ))}
              </div>
            </div>
          </section> */}

          <section id="keyfeatures" className="section bg-main">
            <div class="container">
              <div className="text-center mb-10">
                {/* <h5 class="small-title !text-white">Best Solutions for your Business</h5> */}
                <h4 class="mb-3 !text-white">"Turning Complexity into Clarity: Your Data’s True Potential" </h4>
                {/* <p className="text-white-50 w-7/12 mx-auto">To achieve this, it would be necessary to have uniform grammar, pronunciation and more common that of the individual languages.</p> */}
              </div>
              <div className="space-y-12">
                {/* <div className="flex space-x-10">
                  <div>
                    <span class="badge-alt">
                      <TbBulb size={24} />
                    </span>
                  </div>
                  <div>
                    <h5>Problem</h5>
                    <p>63% of businesses report challenges in deriving actionable insights from their data (Gartner). Challenges predictive capabilities. Organizations often struggle to make informed decisions due to fragmented data sources, inefficient analysis tools, and limited Pain Points</p>
                  </div>
                </div>
                <div className="flex space-x-10">
                  <div>
                    <span class="badge-alt">
                      <TbBulb size={24} />
                    </span>
                  </div>
                  <ul>
                    <h5>Challenges</h5>
                    <p>Inefficient Data Visualization: Many organizations find it difficult to present data in a clear and engaging way, leading to missed insights. </p>
                  </ul>
                </div> */}
                <div className="flex space-x-10">
                  {/* <div>
                    <span class="badge-alt">
                      <TbBulb size={24} />
                    </span>
                  </div> */}
                  <div>
                    {/* <h5>Pain Points</h5> */}
                    <ul className="list-disc space-y-5 text-xl w-full mx-auto lg:w-11/12">
                      <li>63% of businesses report challenges in deriving actionable insights from their data (Gartner). Challenges predictive capabilities. Organizations often struggle to make informed decisions due to fragmented data sources, inefficient analysis tools, and limited Pain Points</li>
                      <li>Inefficient Data Visualization: Many organizations find it difficult to present data in a clear and engaging way, leading to missed insights. </li>
                      <li>Lack of Predictive Analytics: Without advanced forecasting tools, organizations are unable to proactively identify trends and risks. </li>
                      <li>Cumbersome Data Validation Processes: Ensuring data accuracy can be time-consuming and complex, hindering effective decision- making. </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="text-center mt-10">
                <h5 class="mb-3 !text-white leading-relaxed">By addressing these pain points, Reports Detective empowers small business, government and veteran-owned organizations to unlock the full potential of their data.</h5>
              </div>
            </div>
          </section>

          <section id="plans" className="section bg-light">
            <div class="mx-auto max-w-screen-2xl">
              <div className="text-center mb-10">
                <h5 class="small-title">PRICING</h5>
                <h4 class="mb-3">Choose your Plan</h4>
              </div>
              <div className="mb-10 flex justify-center">
                <div className="p-1.5 bg-white rounded-lg pills">
                  <button className={`${planDuration == "Monthly" ? "active" : ""}`} onClick={() => setPlanDuration("Monthly")}>
                    Monthly
                  </button>
                  <button className={`${planDuration == "Yearly" ? "active" : ""}`} onClick={() => setPlanDuration("Yearly")}>
                    Yearly
                  </button>
                </div>
              </div>
              <div class="grid grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-7 w-full mx-auto pricing-plans">
                <div class="plan">
                  <div>
                    <h5>Start Up</h5>
                    <p>Includes 2 users; additional users at $0.50 each (capped at 5 additional users)</p>
                    <div class="price relative">
                      <span>
                        $
                        {plansPricing[0].toFixed(2)}
                      </span>
                      <span> / {planDuration == "Monthly" ? "mo" : "yr"}</span>
                      <div>7-Day Free Trial</div>
                    </div>
                    <a href="#" class="btn-main">
                      Get started
                    </a>
                    <ul role="list" class="space-y-4">
                      {plansFeatures[0].map((feature, index) => (
                        <li key={index}>
                          <FaCheck /> <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div class="plan">
                  <div>
                    <h5>Small Teams & Consultants</h5>
                    <p>Up to 5 users; additional users at $1.00 each</p>
                    <div class="price">
                      <span>
                        $
                        {plansPricing[1].toFixed(2)}
                      </span>
                      <span> / {planDuration == "Monthly" ? "mo" : "yr"}</span>
                      <div>7-Day Free Trial</div>
                    </div>
                    <a href="#" class="btn-main">
                      Get started
                    </a>
                    <ul role="list" class="space-y-4">
                      {plansFeatures[1].map((feature, index) => (
                        <li key={index}>
                          <FaCheck /> <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div class="plan">
                  <div>
                    <h5>Small Business</h5>
                    <p>Up to 30 users; additional users at $1.00 each</p>
                    <div class="price">
                      <span>
                        $
                        {plansPricing[2].toFixed(2)}
                      </span>
                      <span> / {planDuration == "Monthly" ? "mo" : "yr"}</span>
                      <div>7-Day Free Trial</div>
                    </div>
                    <a href="#" class="btn-main">
                      Get started
                    </a>
                    <ul role="list" class="space-y-4">
                      {plansFeatures[2].map((feature, index) => (
                        <li key={index}>
                          <FaCheck /> <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div class="plan">
                  <div>
                    <h5>Government</h5>
                    <p></p>
                    <div class="price">
                      <button className="badge-alt w-full mb-7">Call for Consultation</button>
                    </div>
                    <a href="tel:+1-866-307-8708" className="btn-main-alt mb-[2rem] w-full text-center">
                      1-866-307-8708
                    </a>
                    {/* <a href="#" class="btn-main">
                      Get started
                    </a> */}
                    <ul role="list" class="space-y-4">
                      {plansFeatures[3].map((feature, index) => (
                        <li key={index}>
                          <FaCheck /> <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="download" className="section">
            <div class="container">
              <div className="text-center mb-10">
                <h5 class="small-title">Download</h5>
                <h4 class="mb-2">Multi-Platform Downloads</h4>
                <p className="w-7/12 mx-auto">Download desktop and for Windows, Mac, Android and iOS</p>
              </div>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 text-center">
                {downloads.map((download) => (
                  <div className="mt-8 p-4 rounded-xl hover:bg-slate-50">
                    <a target="_blank" href={`${download.link}`}>
                      <div key={download.name} className="flex flex-col justify-center space-y-5">
                        <img className="max-w-[80px] mx-auto" src={`images/icons/${(download.name).toLowerCase()}.png`}/>
                        <dt className="text-lg text-black">{download.name}</dt>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="newsletter" className="section bg-light">
            <div class="container">
              <div className="text-center mb-14 lg:mb-20">
                <h5 class="small-title">SUBSCRIBE</h5>
                <h4 class="mb-3 w-full md:w-8/12 mx-auto">Subscribe to the newsletter to receive the latest news, updates and new coming features.</h4>
              </div>
              <div>
                <div className="flex flex-col text-center lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5 mx-auto w-full lg:w-8/12">
                  <div className="w-full lg:w-9/12">
                    <input className="w-full" placeholder="Enter your E-mail address" />
                  </div>
                  <div className="w-full lg:w-3/12">
                    <button className="btn-main mx-auto lg:w-full">Subscribe Us</button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section class="bg-main-alt">
            <div class="py-12 px-6 2xl:px-10 mx-auto container sm:py-16">
              <div class="max-w-screen-md mb-8 lg:mb-16">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-main">Get started in 3 easy steps.</h2>
              </div>
              <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                <div>
                  <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">1</div>
                  <h3 class="mb-2 text-xl font-bold text-neutral-900">Start an Instant Meeting</h3>
                  <p class="text-neutral-700 text-sm leading-loose font-light">When you enter the meeting room you will see the automatically generated meeting link to copy.</p>
                </div>
                <div>
                  <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">2</div>
                  <h3 class="mb-2 text-xl font-bold text-neutral-900">Send an Invitation</h3>
                  <p class="text-neutral-700 text-sm leading-loose font-light">Share the meeting room ID or the URL link via email, chat, slack, etc.</p>
                </div>
                <div>
                  <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">3</div>
                  <h3 class="mb-2 text-xl font-bold text-neutral-900">Start your Meeting</h3>
                  <p class="text-neutral-700 text-sm leading-loose font-light">Enter the room ID or directly click the URL link to join the meeting.</p>
                </div>
              </div>
            </div>
          </section>  */}
          <VideoModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </main>
      </motion.div>
    </HomeLayout>
  );
}
