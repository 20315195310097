import "../styles/header.scss";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export default function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isMobileView = useMediaQuery({ query: "(max-width: 1025px)" });

  useEffect(() => {
    if (isMobileView) {
      isMenuOpen ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "auto");
    }

    const handleScroll = () => {
      // Set the sticky state based on scroll position
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMenuOpen, isMobileView]);

  return (
    <>
      <nav className={`py-6 px-5 md:px-0 mx-auto fixed w-full ${isMenuOpen ? "toggled" : "untoggled"} ${location.pathname === "/" && ""}  ${isSticky ? "scrolled" : ""}`}>
        <div class="container flex flex-wrap items-center justify-between mx-auto">
          <div className="flex items-center mr-4">
            <Link to="/" class="flex items-center space-x-4">
              <img src={`${isSticky ? "/logo.png" : "/logo-white.png"}`} className="h-8 lg:h-14" />
            </Link>
          </div>
          <div class={`flex ${isMobileView ? "" : "hidden"}`}>
            <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex justify-center items-center px-3 py-1.5 text-sm text-gray-500 rounded-md toggler" aria-controls="navbar-cta" aria-expanded={isMenuOpen} onClick={toggleMenu}>
              <span class="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <motion.path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" animate={{ rotate: isMenuOpen ? 45 : 0, y: isMenuOpen ? 0 : 0 }} initial={{ rotate: 0, y: 0 }} exit={{ opacity: 0 }} transition={{ type: "spring", duration: 0.5 }} />

                <motion.path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" animate={{ opacity: isMenuOpen ? 0 : 1 }} initial={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: "spring", duration: 0.5 }} />

                <motion.path fillRule="evenodd" d="M3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" animate={{ rotate: isMenuOpen ? -45 : 0, y: isMenuOpen ? 0 : 0 }} initial={{ rotate: 0, y: 0 }} exit={{ opacity: 0 }} transition={{ type: "spring", duration: 0.5 }} />
              </svg>
            </button>
          </div>
          <motion.div initial={isMobileView ? { x: "100%" } : {}} animate={isMobileView ? { x: isMenuOpen ? "0%" : "100%" } : {}} exit={isMobileView ? { x: "100%" } : {}} transition={{ type: "tween", duration: 0.3 }} className={`items-center justify-between lg:flex lg:flex-1 md:order-1 w-full ${isMobileView && !isMenuOpen ? "hidden" : ""}`} id="navbar-cta">
            <div className="flex h-full flex-1">
              <div class="flex flex-col items-center justify-end flex-1 p-4 md:p-0 mt-4 ml-0 lg:ml-10 rounded-lg md:flex-row space-x-4 lg:space-x-5 md:mt-0 md:border-0 nav-links">
                <div>
                  <Link to="/#home" className="active">Home</Link>
                </div>
                <div>
                  <Link to="/#why-us">Why Us</Link>
                </div>
                <div>
                  <Link to="/#features">Features</Link>
                </div>
                <div>
                  <Link to="/#clients">Clients</Link>
                </div>
                <div>
                  <Link to="/#plans">Plans</Link>
                </div>
                <div>
                  <Link to="/#download">Download</Link>
                </div>
              </div>

              <div className="sm-toggler">
                <div className=" w-full flex flex-col justify-between">
                  <div className="w-full p-3 text-white text-2xl flex flex-col space-y-5 text-right mt-16">
                    <div>
                      <Link to="/#home" onClick={toggleMenu}>
                        Home
                      </Link>
                    </div>
                    <div>
                      <Link to="/#why-us" onClick={toggleMenu}>
                        Why Us
                      </Link>
                    </div>
                    <div>
                      <Link to="/#features" onClick={toggleMenu}>
                        Features
                      </Link>
                    </div>
                    <div>
                      <Link to="/#clients" onClick={toggleMenu}>
                        Clients
                      </Link>
                    </div>
                    <div>
                      <Link to="/#plans" onClick={toggleMenu}>
                        Plans
                      </Link>
                    </div>
                    <div>
                      <Link to="/#download" onClick={toggleMenu}>
                        Download
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div class="flex flex-col space-y-5 p-3 text-lg text-right">
                      <Link to="/privacy" class="text-neutral-500 hover:text-neutral-700">
                        Privacy Policy
                      </Link>
                      <Link to="/terms" class="text-neutral-500 hover:text-neutral-700">
                        Terms and Conditions
                      </Link>
                    </div>
                    <div className="flex flex-row justify-end space-x-3 px-3">
                      <div>
                        <Link to="#" onClick={toggleMenu} className="btn-main !rounded-md flex-1 !px-5">
                          Log in
                        </Link>
                      </div>
                      <div>
                        <Link to="/#plans" onClick={toggleMenu} className="btn-main-alt !rounded-md flex-1 !px-5">
                          Sign up for free
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-end ml-4 nav-buttons">
                <div>
                  <Link to="/#plans">
                    <button className="btn navbar-btn !rounded-full">Try for Free</button>
                  </Link>
                </div>

                {/* <div>
                  <Link to="/signup" className="btn-main !px-4 lg:!px-8 mr-4">
                    Log in
                  </Link>
                </div>
                <div>
                  <Link to="/login" className="btn-main-alt !px-4 lg:!px-8">
                    Sign Up
                  </Link>
                </div> */}
              </div>
            </div>
          </motion.div>
        </div>
      </nav>
    </>
  );
}
